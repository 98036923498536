<!--
 * @Author: your name
 * @Date: 2021-03-03 14:36:07
 * @LastEditTime: 2021-03-15 13:39:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\divs\scheduling\scheduling.vue
-->
<template>
  <div class="department">
    <template v-if="dotctorStatus">
      <div class="introduce">
        <div class="titles">
          科室简介
        </div>
        <div class="con">
          <div>
            耳鼻咽喉头颈外科组建于1985年，在各级领导的关怀下，不断发展壮大，现已经形成具有一定规模和专科医疗特色的医教研全面发展的科室。
            现有床位90张，医护人员69名，其中主任医师、教授4名，副主任医师5名，主治医师4名，医师8名，技师7名，护理人员40名。其中博士4名，硕士3名。
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="body">
        <div class="info">
          <div class="pic">
            <img class="img" :src="doctorList.txljdz">
          </div>
          <div class="con">
            <div class="name">
              {{ doctorList.ysxm }}
              <span class="post">{{ doctorList.zc == null ? '无' : doctorList.zc }}</span>
            </div>
            <div>{{ doctorList.ksmc }}</div>
          </div>
        </div>
        <div class="desc">
          <span class="label">
            擅长：
          </span>
          {{ doctorList.yssc == null ? '无' : doctorList.yssc }}
        </div>
        <div :class="['desc',{'packup':hide}]">
          <span class="label">
            简介：
          </span>
          {{ doctorList.ysjj == null ? '无' : doctorList.ysjj }}
        </div>
        <div class="showTextBtn" @click="showCure">
          <span v-show="hide">展开 <van-icon name="arrow-down" /></span><span v-show="!hide">收起<van-icon name="arrow-up" /></span>
        </div>
        <div class="line" />
      </div>
    </template>
    <div class="scheduling">
      <div class="title">
        {{ dotctorStatus == false ? '医生排班' : '科室排班' }}
      </div>
      <div class="date">
        <div class="date-left">
          <div class="date-left-item">
            <div style="	padding: 20rpx;margin: 0 auto;">
              <div class="left-name">
                普通号
              </div>
              <div class="left-morning">
                上午
              </div>
              <div class="left-afternoon">
                下午
              </div>
            </div>
          </div>
        </div>
        <div class="date-right">
          <div scroll-x="true" class="scroll-content">
            <div class="date-right-item">
              <div v-for="(item,index) in workList" :key="index" style="flex:1;">
                <div class="date-top">
                  <div>{{ item.mouth }}</div>
                  <div>{{ item.date }}</div>
                </div>
                <div v-for="(val, key, indexs) in item.day" :key="indexs" :class="['source',{'bgcolor':!val.hyzs>0}]"
                     @click="haveSource(val)"
                >
                  <span>{{ val.hyzs > 0 ? '有号' : '无' }}</span>
                </div>
                <!-- <div
                  v-show="item.xiawu=='有号'"
                  class="source"
                  @click="haveSource(item)"
                >
                  {{ item.xiawu }}
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getItem} from '../../utils/cache'
import {DOCTOR_DETAIL, CONSTANT} from '../../utils/constant'
import {appointModel} from '../../api/appoint'
import {getDate, addDate, format} from '../../utils/index'
import {SET_HEADER_CONFIG} from '../../store/mutation-types'

export default {
  data() {
    return {
      dotctorStatus: false,
      doctorList: [],
      hide: true,
      isDoctor: false,
      bagin:getDate(),
      end: addDate(getDate(), 6),
      workList: []
    }
  },
  computed: {},
  mounted() {
    const {id,ksmc} = this.$route.query
    if(ksmc){
      this.$store.commit(`header/${SET_HEADER_CONFIG}`, { title:ksmc})
    }
    if (id === '102' || id === '103') {
      this.dotctorStatus = false
      this.isDoctor = true
      this.doctorList = getItem(DOCTOR_DETAIL, {})
      this.Scheduling()
    } else {
      this.dotctorStatus = true
      this.isDoctor = false
      this.Scheduling()
    }
  },
  methods: {
    showCure() {
      this.hide = !this.hide
    },
    haveSource(item) {
      if(item.hyzs){
        this.$router.push({
          path: '/appointment',
          query: {
            hylx: item.hylxmc,
            ksmc: item.ksmc,
            pbrq: item.pbrq,
            ksdm: item.ksdm,
            sxwbzmc: item.sxwbzmc,
            ghf: item.ghf,
            id: item.id
          }
        })
      }

    },
    Scheduling() {
      const {id, yljgdm, ysgh, ksdm} = this.$route.query
      let params = {
        dateBegin: this.bagin,
        dateEnd: this.end,
        hylx: id,
        yljgdm: yljgdm,
        ksdm: this.isDoctor ? '' : ksdm,
        gh: this.isDoctor ? ysgh : ''
      }
      appointModel.scheduling(params).then(res => {
        if (res.code == CONSTANT.SUCCESS_CODE) {
          this.workList = format(res.data)

        }
      })
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";

div {
  box-sizing: border-box;
}

.title {
  margin-bottom: 0px;
  line-height: 45px;
  font-size: 32px;
  font-weight: bold;
  color: #3d3d3d;
}
.titles {
  margin-bottom: 25px;
  font-size: 32px;
  font-weight: bold;
  color: #3d3d3d;
}
.scroll-content {
  white-space: nowrap; // 滚动必须加的属性
  width: 100%;
  height: 280px;
  padding: 20px;
  margin: 0 auto;
}

.introduce {
  margin-bottom: 0px;
  padding: 32px 0px;
  line-height: 33px;
  font-size: @font-size-26;
  color: @middle-font-color;
  background: @white-color;
  border-radius: 20px;

}
.body{
  padding: 28px 0px 28px 0px;
  .info {
    display: flex;
    margin-bottom: 20px;
  }

  .pic,
  .img {
    width: 102px;
    height: 102px;
    border-radius: 50%;
    background: #ccc;
  }

  .con {
    flex: 1;
    padding-left: 32px;
    line-height: 33px;
    font-size: 26px;
    color: @middle-font-color;

    .name {
      align-items: center;
      margin-bottom: 19px;
      line-height: 45px;
      font-size: 35px;
      font-weight: bold;
      color: #3d3d3d;

      .post {
        margin-left: 14px;
        font-weight: normal;
        font-size: 28px;
      }
    }
  }

  .statistic {
    display: flex;
    text-align: center;
    margin-top: 40px;

    .statistic-item {
      margin-right: 72px;

      .num {
        padding-top: 12px;
        color: @theme-color;
      }
    }
  }

  .btn {
    position: absolute;
    top: 9px;
    right: 0;
    z-index: 2;
    width: 130px;
    height: 48px;
    line-height: 46px;
    text-align: center;
    color: @theme-color;
    border: 1px solid @theme-color;
    border-radius: 30px;

    &.active {
      color: @white-color;
      background: @theme-color;
    }
  }

  .desc {
    margin-bottom: 24px;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      margin-bottom: 24px;
      font-weight: bold;
      color: @theme-color;
    }
  }

  .packup {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .showTextBtn {
    text-align: center;
    color: #999;
    font-size: 22px;
   padding-bottom: 20px;
  }
  .line{
    background: #f5f5f5;
    margin-left: -32px;
    margin-right: -32px;
    height: 16px;
  }
}

.department {
  position: relative;
  padding: 0px 32px;
  font-size: 28px;
  color: #666;

  .scheduling {
    position: relative;

    .title {
    }

    .date {
      display: flex;
      flex-direction: row;
      width: 100%;

      .date-left {
        width: 15%;
        font-size: 32px;

        .left-name {
          margin: 40px 0px 0px 0px;
        }

        .left-morning {
          margin: 57px 0px 20px 0px;
        }

        .left-afternoon {
          margin: 20px 0px;
        }
      }

      .date-right {
        width: 85%;
        font-size: 32px;
        overflow: auto;

        .date-right-item {
          display: flex;

          .date-top {
            margin: 20px 0;
            text-align: center;
          }

          .source {
            margin: 20px 10px;
            height: 46px;
            width: 95px;
            line-height: 46px;
            background: rgba(28, 156, 246, 1);
            border-radius: 23px;
            text-align: center;
            font-size: 32px;
            color: #fff;
          }

          .bgcolor {
            background: #dddddd;
          }

          .unsurce {
            margin: 20px 0px;
            height: 32px;
            line-height: 32px;
            background: #fff;
            border-radius: 18px;
            text-align: center;
            font-size: 24px;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
